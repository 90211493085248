<template>
	<footer
		class="flex flex-col md:grid py-10 md:py-[0] pl-6 md:pl-[0] md:grid-cols-12 rounded-none border-t border-t-mine-shaft"
	>
		<div class="md:col-span-3 md:pl-10 md:py-10 md:border-r md:border-r-mine-shaft">
			<a href="https://indital-group.com/" target="_blank">@ LLC "INDITAL CUSTOMS"</a>
		</div>
		<div class="hidden md:block md:col-span-3 md:border-r md:border-r-mine-shaft" />
		<div class="hidden md:block md:col-span-3 md:border-r md:border-r-mine-shaft" />
		<div class="md:col-span-3 md:text-right md:pr-10 md:py-10">Privacy Policy</div>
	</footer>
</template>
